<template>
  <b-card
    align="left"
    class="booking-closed-card side-card"
    :class="{ 'has-bookings': areThereBookings }"
    header-tag="header"
    no-body
  >
    <template #header>
      <b-row>
        <b-col>
          <h5>Bookings cerrados</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="booking-wrapper" v-if="!areThereBookings">
            <div>
              <strong>¡Vaya!</strong>
            </div>
            Parece que no tienes bookings cerrados
          </div>
        </b-col>
      </b-row>
    </template>
    <b-card-body v-if="areThereBookings" class="booking-list">
      <b-container>
        <b-row v-for="booking in bookings" :key="booking.id">
          <b-col cols="3" sm="2">
            <div class="booking-code">
              {{ booking.getCode }}
            </div>
          </b-col>
          <b-col cols="9" sm="7">
            <div class="booking-name">{{ booking.getName }}</div>
            <div class="booking-event-date">{{ booking.getEventDate }}</div>
            <div class="booking-location">{{ booking.getShortLocation }}</div>
          </b-col>
          <b-col cols="12" sm="3" class="mt-4 mt-sm-0">
            <b-button
              class="float-right w-100"
              @click="$emit('onViewDetailBooking', booking.getId)"
              variant="secondary">
              Ver
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ClosedHardwareBooking',
  computed: {
    ...mapGetters({ bookings: 'hardwareBooking/closed' }),
    areThereBookings () {
      return this.bookings.length > 0
    }
  }
}
</script>

<style lang="less" scoped>
@import 'closedHardwareBooking';
</style>
