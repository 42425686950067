<template>
  <div id="edit-project">
    <b-card
      title="Detalle de proyecto"
      class="user-create-card"
      align="left"
    >
      <ProjectForm
        ref="projectForm"
        :form_prop.sync="form"
        @onNewEmployee="onNewEmployee"
        @onNewHardware="onNewHardware"
      />
      <b-row class="footer">
        <b-col>
          <b-button variant="secondary" @click="onRemove" class="d-flex">
            <b-icon-trash></b-icon-trash>
          </b-button>
        </b-col>
        <b-col class="footer-buttons">
          <b-button variant="primary" @click="onSave(true)">
            Guardar cambios
          </b-button>
        </b-col>
      </b-row>
      <RemoveConfirmationModal
        id="confirmationModalId"
        @onConfirmRemoveConfirmation="onConfirmedRemove"
        @onCancelRemoveConfirmation="onCancelRemove"
      />
    </b-card>
  </div>
</template>

<script>
import ProjectForm from '@/components/Business/Project/Form/ProjectForm.vue'
import RemoveConfirmationModal from '@/components/Modal/RemoveConfirmationModal'
import { BIconTrash } from 'bootstrap-vue'

export default {
  name: 'EditProject',
  components: {
    ProjectForm,
    RemoveConfirmationModal,
    BIconTrash
  },
  props: {
    form_prop: Object
  },
  data () {
    return {
      form: this.form_prop
    }
  },
  watch: {
    form_prop (value) {
      this.form = value
    }
  },
  methods: {
    async onSave () {
      const isFormValid = await this.$refs.projectForm.$refs.projectForm.validate()
      if (!isFormValid) {
        return
      }
      this.lockScreen()
      this.$store.dispatch('project/update', this.form)
        .then(() => {
          this.unlockScreen()
          this.$store.dispatch('showConfirmationModal')
        })
    },
    onRemove () {
      this.$bvModal.show('confirmationModalId')
    },
    onCancelRemove () {
      this.$bvModal.hide('confirmationModalId')
    },
    onConfirmedRemove () {
      this.lockScreen()
      this.$store.dispatch('project/delete', this.form.id)
        .then(() => {
          this.onCancelRemove()
          this.unlockScreen()
          this.$store.dispatch('showConfirmationModal')
          this.$router.push({ name: 'project-list' })
        })
    },
    onNewEmployee (id) {
      this.$bvModal.hide(id)
      this.onSave()
      this.$router.push({ name: 'employee-create' })
    },
    onNewHardware (id) {
      this.$bvModal.hide(id)
      this.onSave()
      this.$router.push({ name: 'hardware-create' })
    }
  }
}
</script>

<style lang="less">
  @import 'editProject.less';
</style>
