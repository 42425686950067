<template>
  <div id="detail-project">
    <Header
      :user="loggedUser"
      :currentRouteName="$route.name"
    />
    <b-container class="detail-project-layout">
      <b-row>
        <b-col>
          <h4 class="text-left">Detalle de proyecto</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-row>
            <b-col class="d-none d-sm-block">
              <BusinessInfoCard
                :loggedUser="loggedUser"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="d-none d-sm-block">
              <b-card class="side-card">
                <MenuItem
                  title="Detalle de proyecto"
                  icon="booking.svg"
                  @onclickMenuItem="displayedCard = 'edit'"
                  :class="{ 'active' : displayedCard === 'edit'}"
                />
                <div class="d-flex parent-menu">
                  <img
                    class="MenuItem-image"
                    :src="require('@/assets/icons/empty-user.svg')"
                  >
                  <span class="text-l ml-1 MenuItem-link">
                    Booking de técnicos
                  </span>
                </div>
                <div class="sub-menu">
                  <MenuItem
                    title="Abiertos"
                    icon="booking-opened.svg"
                    @onclickMenuItem="displayedCard = 'open-technician-bookings'"
                    :class="{ 'active' : displayedCard === 'open-technician-bookings'}"
                  />
                  <MenuItem
                    title="Cerrados"
                    icon="booking-closed.svg"
                    @onclickMenuItem="displayedCard = 'closed-technician-bookings'"
                    :class="{ 'active' : displayedCard === 'closed-technician-bookings'}"
                  />
                  <MenuItem
                    title="Vencidos"
                    icon="booking-expired.svg"
                    @onclickMenuItem="displayedCard = 'expired-technician-bookings'"
                    :class="{ 'active' : displayedCard === 'expired-technician-bookings'}"
                  />
                </div>
                <div class="d-flex parent-menu">
                  <img
                    class="MenuItem-image"
                    :src="require('@/assets/icons/speaker.svg')"
                  >
                  <span class="text-l ml-1 MenuItem-link">
                    Booking de hardware
                  </span>
                </div>
               <div class="sub-menu">
                  <MenuItem
                    title="Abiertos"
                    icon="booking-opened.svg"
                    @onclickMenuItem="displayedCard = 'open-hardware-bookings'"
                    :class="{ 'active' : displayedCard === 'open-hardware-bookings'}"
                  />
                  <MenuItem
                    title="Cerrados"
                    icon="booking-closed.svg"
                    @onclickMenuItem="displayedCard = 'closed-hardware-bookings'"
                    :class="{ 'active' : displayedCard === 'closed-hardware-bookings'}"
                  />
                  <MenuItem
                    title="Vencidos"
                    icon="booking-expired.svg"
                    @onclickMenuItem="displayedCard = 'expired-hardware-bookings'"
                    :class="{ 'active' : displayedCard === 'expired-hardware-bookings'}"
                  />
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col col>
          <div
            v-if="displayedCard == 'edit'"
          >
            <EditProject
              v-if="project"
              :form_prop.sync="project"
            />
          </div>
          <div
            v-if="displayedCard == 'open-technician-bookings'"
          >
            <OpenedTechnicianBooking
              @onViewDetailBooking="onDetailTechnicianBooking"
            />
          </div>
          <div
            v-if="displayedCard == 'closed-technician-bookings'"
          >
            <ClosedTechnicianBooking
              @onViewDetailBooking="onDetailTechnicianBooking"
            />
          </div>
          <div
            v-if="displayedCard == 'expired-technician-bookings'"
          >
            <ExpiredTechnicianBooking />
          </div>
          <div
            v-if="displayedCard == 'open-hardware-bookings'"
          >
            <OpenedHardwareBooking
              @onViewDetailBooking="onDetailHardwareBooking"
            />
          </div>
          <div
            v-if="displayedCard == 'closed-hardware-bookings'"
          >
            <ClosedHardwareBooking
              @onViewDetailBooking="onDetailHardwareBooking"
            />
          </div>
          <div
            v-if="displayedCard == 'expired-hardware-bookings'"
          >
            <ExpiredHardwareBooking />
          </div>
          <div
            v-if="displayedCard == 'detail-technician-booking'"
          >
            <DetailTechnicianBooking
              @onViewDetailPosition="onDetailTechnicianPosition"
            />
          </div>
          <div
            v-if="displayedCard == 'detail-technician-position'"
          >
            <DetailTechnicianPositionNative />
          </div>
          <div
            v-if="displayedCard == 'detail-hardware-booking'"
          >
            <DetailHardwareBooking
              @onViewDetailPosition="onDetailHardwarePosition"
            />
          </div>
          <div
            v-if="displayedCard == 'detail-hardware-position'"
          >
            <DetailHardwarePosition />
          </div>
          <!-- <slot /> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MenuItem from '@/components/Config/MenuItem'
import Header from '@/components/Header/Header.vue'
import BusinessInfoCard from '@/components/Business/Card/BusinessInfo/BusinessInfoCard.vue'
import EditProject from '@/components/Business/Project/Edit/EditProject.vue'
import OpenedTechnicianBooking from '@/components/Business/Project/TechnicianBooking/List/Opened/OpenedTechnicianBooking.vue'
import ClosedTechnicianBooking from '@/components/Business/Project/TechnicianBooking/List/Closed/ClosedTechnicianBooking.vue'
import ExpiredTechnicianBooking from '@/components/Business/Project/TechnicianBooking/List/Expired/ExpiredTechnicianBooking.vue'
import DetailTechnicianBooking from '@/components/Business/Project/TechnicianBooking/Detail/DetailTechnicianBooking.vue'
import DetailTechnicianPositionNative from '@/components/Business/Project/TechnicianPosition/Detail/Native/DetailTechnicianPositionNative.vue'
import OpenedHardwareBooking from '@/components/Business/Project/HardwareBooking/List/Opened/OpenedHardwareBooking.vue'
import ClosedHardwareBooking from '@/components/Business/Project/HardwareBooking/List/Closed/ClosedHardwareBooking.vue'
import ExpiredHardwareBooking from '@/components/Business/Project/HardwareBooking/List/Expired/ExpiredHardwareBooking.vue'
import DetailHardwareBooking from '@/components/Business/Project/HardwareBooking/Detail/DetailHardwareBooking.vue'
import DetailHardwarePosition from '@/components/Business/Project/HardwarePosition/Detail/DetailHardwarePosition.vue'

export default {
  name: 'DetailProject',
  components: {
    Header,
    BusinessInfoCard,
    MenuItem,
    EditProject,
    OpenedTechnicianBooking,
    ClosedTechnicianBooking,
    ExpiredTechnicianBooking,
    DetailTechnicianBooking,
    DetailTechnicianPositionNative,
    OpenedHardwareBooking,
    ClosedHardwareBooking,
    ExpiredHardwareBooking,
    DetailHardwareBooking,
    DetailHardwarePosition
  },
  data () {
    return {
      displayedCard: 'edit'
    }
  },
  created () {
    this.getOneById(this.$route.params.id)
    this.getAllEmployees()
    this.getAllHardware()
    this.getAllVenue()
    this.getAllTechnicianBooking(this.$route.params.id)
    this.getAllHardwareBooking(this.$route.params.id)
    this.listExternalTechnician(this.$route.params.id)
  },
  computed: {
    ...mapGetters({ project: 'project/current' }),
    loggedUser () {
      return this.$store.getters.loggedUser
    }
  },
  methods: {
    ...mapActions('project', ['getOneById', 'listExternalTechnician']),
    ...mapActions('employee', { getAllEmployees: 'getAll' }),
    ...mapActions('hardware', { getAllHardware: 'getAll' }),
    ...mapActions('venues', { getAllVenue: 'getAll' }),
    ...mapActions('technicianBooking', {
      getAllTechnicianBooking: 'getAll',
      getTechnicianBookingById: 'getOneById'
    }),
    ...mapActions('hardwareBooking', {
      getAllHardwareBooking: 'getAll',
      getHardwareBookingById: 'getOneById'
    }),
    onDetailTechnicianBooking (id) {
      const payload = {
        projectId: this.$route.params.id,
        id: id
      }
      this.getTechnicianBookingById(payload)
      this.displayedCard = 'detail-technician-booking'
    },
    onDetailTechnicianPosition () {
      this.displayedCard = 'detail-technician-position'
    },
    onDetailHardwareBooking (id) {
      const payload = {
        projectId: this.$route.params.id,
        id: id
      }
      this.getHardwareBookingById(payload)
      this.displayedCard = 'detail-hardware-booking'
    },
    onDetailHardwarePosition () {
      this.displayedCard = 'detail-hardware-position'
    }
  }
}
</script>

<style lang="less">
  @import 'detailProject.less';
</style>
