<template>
  <div>
    <b-card
      align="left"
      class="booking-opened-card side-card"
      :class="{ 'has-bookings': areThereBookings }"
      header-tag="header"
      no-body
    >
      <template #header>
        <b-row>
          <b-col>
            <h5>Bookings abiertos</h5>
            <div>
              <b-button
                v-b-modal.create-hardware-booking-modal
                class="float-right small d-inline-block d-sm-none"
                type="button"
                variant="outline"
              >
                <img :src="require('@/assets/icons/create-icon.svg')">
              </b-button>
              <b-button
                v-if="areThereBookings"
                v-b-modal.create-hardware-booking-modal
                class="float-right small d-none d-sm-inline-block"
                type="button"
                variant="outline"
              >
                <img :src="require('@/assets/icons/create-icon.svg')">
              </b-button>
              <b-button
                v-else
                v-b-modal.create-hardware-booking-modal
                class="float-right big text-nowrap d-none d-sm-inline-block"
                type="button"
                variant="primary"
              >
                <img :src="require('@/assets/icons/create-icon.svg')">
                Crear booking
              </b-button>
              <CreateHardwareBookingModal />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="booking-wrapper" v-if="!areThereBookings">
              <div>
                <strong>¡Vaya!</strong>
              </div>
              Parece que no tienes bookings abiertos
            </div>
          </b-col>
        </b-row>
      </template>
      <b-card-body v-if="areThereBookings" class="booking-list">
        <b-container>
          <b-row v-for="booking in bookings" :key="booking.id">
            <b-col cols="3" sm="2">
              <div class="booking-code">
                {{ booking.getCode }}
              </div>
            </b-col>
            <b-col cols="9" sm="7">
              <div class="booking-name">{{ booking.getName }}</div>
              <div class="booking-event-date">{{ booking.getEventDate }}</div>
              <div class="booking-location">{{ booking.getShortLocation }}</div>
            </b-col>
            <b-col cols="12" sm="3" class="mt-4 mt-sm-0">
              <b-button
                class="float-right w-100"
                @click="$emit('onViewDetailBooking', booking.getId)"
                variant="secondary">
                Ver
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>
    <b-card
      align="left"
      class="booking-opened-card side-card"
      :class="{ 'has-bookings': areThereDraftBookings }"
      header-tag="header"
      no-body
    >
      <template #header>
        <b-row>
          <b-col>
            <h5>Bookings en borrador</h5>
            <div>
              <b-button
                v-b-modal.create-hardware-booking-modal
                class="float-right small d-inline-block d-sm-none"
                type="button"
                variant="outline"
              >
                <img :src="require('@/assets/icons/create-icon.svg')">
              </b-button>
              <b-button
                v-b-modal.create-hardware-booking-modal
                class="float-right small d-none d-sm-inline-block"
                type="button"
                variant="outline"
                v-if="areThereDraftBookings"
              >
                <img :src="require('@/assets/icons/create-icon.svg')">
              </b-button>
              <b-button
                v-b-modal.create-hardware-booking-modal
                class="float-right big text-nowrap d-none d-sm-inline-block"
                type="button"
                variant="primary"
                v-else
              >
                <img :src="require('@/assets/icons/create-icon.svg')">
                Crear booking
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="booking-wrapper" v-if="!areThereDraftBookings">
              <div>
                <strong>¡Vaya!</strong>
              </div>
              Parece que no tienes bookings en borrador
            </div>
          </b-col>
        </b-row>
      </template>
      <b-card-body v-if="areThereDraftBookings" class="booking-list">
        <b-container>
          <b-row v-for="booking in draftBookings" :key="booking.id">
            <b-col cols="3" sm="2">
              <div class="booking-code">
                {{ booking.getCode }}
              </div>
            </b-col>
            <b-col cols="9" sm="7">
              <div class="booking-name">{{ booking.getName }}</div>
              <div class="booking-event-date">{{ booking.getEventDate }}</div>
              <div class="booking-location">{{ booking.getShortLocation }}</div>
            </b-col>
            <b-col cols="12" sm="3" class="mt-4 mt-sm-0">
              <b-button
                class="float-right w-100"
                @click="$emit('onViewDetailBooking', booking.getId)"
                variant="secondary">
                Ver
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CreateHardwareBookingModal from '@/components/Business/Project/HardwareBooking/Create/CreateHardwareBookingModal.vue'

export default {
  name: 'OpenedHardwareBooking',
  components: {
    CreateHardwareBookingModal
  },
  computed: {
    ...mapGetters({ openHardwareBookings: 'hardwareBooking/opened' }),
    bookings () {
      return this.openHardwareBookings.filter((booking) => {
        return booking.getBroadcasted
      })
    },
    draftBookings () {
      return this.openHardwareBookings.filter((booking) => {
        return !booking.getBroadcasted
      })
    },
    areThereBookings () {
      return this.bookings.length > 0
    },
    areThereDraftBookings () {
      return this.draftBookings.length > 0
    }
  }
}
</script>
<style lang="less" scoped>
@import 'openedHardwareBooking';
</style>
